import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { ul, p } from "./AllReviews.module.css"

const query = graphql`
  {
    allAirtable(limit: 300) {
      nodes {
        data {
          Review
          Title

          ID
          Customer_Name
        }
      }
    }
  }
`

const AllReviews = () => {
  const data = useStaticQuery(query)
  const reviews = data.allAirtable.nodes

  return (
    <section className="reviews-container">
      <ul className="reviews-list">
        {reviews.map(review => {
          const { ID, Title, Review, Customer_Name } = review.data

          return (
            <li className="review-item">
              <Link to={`/${ID}`} key={ID} className="review-title">
                <h2>{Title}</h2>
              </Link>

              <p>by {Customer_Name}</p>
              <p>{Review.substring(0, 250)}...</p>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default AllReviews
